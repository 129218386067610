<template>
  <v-card>
    <v-card-title>Edit User</v-card-title>

    <v-container>
     <UserForm :dbUser="dbUser" :umUser="umUser" type="UPDATE" />
    </v-container>
  </v-card>
</template>

<script>
import UserForm from "./form.vue";
import RestResource from "../../services/dataServiceCD";
const service = new RestResource();

export default {
  components: {
    UserForm
  },
  data() {
    return {
      umUser: {},
      dbUser: {},
    };
  },
  mounted() {
    this.fetchUsers();
  },

  methods: {
    fetchUsers() {
      let data = {
        userId: this.$route.params.id,
        rawData: true
      };
      this.$setLoader();

      service.fetchUsers(data).then(r => {
        this.$disableLoader();

        this.umUser = r.data[0].umUser;
        this.dbUser = r.data[0].dbUser;
      });
    }
  }
};
</script>
